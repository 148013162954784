<template>
  <v-container fluid>
    <v-row no-gutters class="pos product-management">
        <v-col md="12" class="pa-4">
            <v-row>
                <v-col md="5" lg="3">
                    <div class="pm-title"><v-icon class='dark-blue-color' @click="backToPos" title="Back to POS">mdi-arrow-left</v-icon> {{ pmClass?'Product':cmClass?'Category':amClass?'Attribute':'Inventory'}} Management</div>
                </v-col>
                <v-col md="7" lg="6">
                    <div class="center-div">
                       <template>
                            <v-row justify="center">
                                <v-col
                                    align="center"
                                    cols="6"
                                    md="3"
                                    :class="[pmClass ? 'btn_bg' : '']"
                                    >
                                    <router-link :to="`/pos/product-management`">
                                        <div class="salesBtn">Products</div>
                                    </router-link>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="6"
                                    md="3"
                                    :class="[amClass ? 'btn_bg' : '']"
                                    >
                                    <router-link :to="`/pos/attribute-management`">
                                        <div class="salesBtn">Attributes</div>
                                    </router-link>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="6"
                                    md="3"
                                    :class="[cmClass ? 'btn_bg' : '']"
                                    >
                                    <router-link :to="`/pos/category-management`">
                                        <div class="salesBtn">Category</div>
                                    </router-link>
                                </v-col>
                                <v-col
                                    align="center"
                                    cols="6"
                                    md="3"
                                    :class="[imClass ? 'btn_bg' : '']"
                                    >
                                    <router-link :to="`/pos/inventory-management`">
                                        <div class="salesBtn">Inventory Logs</div>
                                    </router-link>
                                </v-col>
                            </v-row>
                        </template>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col md="12" class="mt-5">
            <v-card class="pa-4 pm-card">
                <v-row>
                    <v-col md="3" sm="6">
                        <div class="product-search">
                            <v-text-field
                                v-model="productNameSearch"
                                density="compact"
                                variant="solo"
                                :label="pmClass?'Search Product':cmClass?'Search Category':amClass?'Search Attribute':'Search Inventory'"
                                append-inner-icon="mdi-magnify"
                                single-line
                                hide-details
                                @keyup.enter="searchData"
                                @click:append="searchData"
                                clearable
                                @click:clear="clearSearch"
                                class="ps-field"
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col md="9" sm="6" class="pb-0">
                        <div class="right-div" style="text-align: right;">
                            <v-btn class="btn-add-product" @click="addData" v-if="checkWritePermission($modules.pos.management.slug)">+ {{ pmClass?'Add Product':cmClass?'Add Category':amClass?'Add Attribute':'Add Stock'}}</v-btn>
                            <v-btn class="btn-add-product ml-1" @click="addProductStock(null)" v-if="checkWritePermission($modules.pos.management.slug) && pmClass">+ Add Stock</v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="12" class="">
                        <table class="pm-table mb-5" id="pm-table" with="100%" v-if="pmClass">
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Category</th>
                                <th>Price</th>
                                <th>Tax</th>
                                <th>Total Price</th>
                                <th>Stock Level</th>
                                <th>Inventory</th>
                                <th>Action</th>
                            </tr>
                            <tr v-for="(product, index) in products" :key="index">
                                <td width="100px">
                                    <div class="md-table-cell-container md-ripple md-disabled d-flex justify-center align-center" style="z-index: 0;">
                                        <img :src="getImage(product.image)" aspect-ratio="1" width="40"/>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ product.name }}
                                        <div class="p-variant" v-if="product.variants && product.variants.length > 0">
                                            <v-icon>mdi-package-variant</v-icon> 
                                            <v-chip v-for="(variant,index) in product.variants" :key="index"  class="ma-2" small>
                                                {{variant.attribute_name }}: {{ variant.total_price | toCurrency }}
                                                {{ variant.enable_retail_inventory?', Stock: '+variant.stock_level:"" }}
                                            </v-chip>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ product.category }}
                                    </div>
                                </td>
                                <td>
                                    {{ product.price | toCurrency }}
                                </td>
                                <td>
                                    {{ product.tax_amount | toCurrency }}
                                </td>
                                <td>
                                    {{ product.total_price | toCurrency }}
                                </td>
                                <td>
                                    <div>
                                        {{ product.stock_level }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <v-btn  x-small :color="product.enable_retail_inventory?'success':''">{{ product.enable_retail_inventory ?'Enabled':'Disabled' }}</v-btn>
                                    </div>
                                </td>
                               
                                <td>
                                    <v-icon class='dark-blue-color ma-1' @click="addProductStock(product.id)" title="Add Stock" v-if="checkWritePermission($modules.pos.management.slug) && product.enable_retail_inventory">mdi-package-variant-closed</v-icon>
                                    <v-icon class='dark-blue-color ma-1' @click="editProduct(product.id)" title="Edit Product" v-if="checkWritePermission($modules.pos.management.slug)">mdi-pencil-outline</v-icon>
                                    <v-icon class='dark-blue-color ma-1' @click="deleteData(product.id,'product')" title="Delete Product"  v-if="checkDeletePermission($modules.pos.management.slug)">mdi-delete-outline</v-icon>
                                </td>
                            </tr>
                        
                            <tr v-if="products && !products.length">
                                <th colspan="7">Products Not Found</th>
                            </tr>
                        </table>
                        <table class="pm-table mb-5" id="pm-table" with="100%" v-if="cmClass">
                            <tr>
                                <th>Category Name</th>
                                <th>Product</th>
                                <th>Action</th>
                            </tr>
                            <tr v-for="(category, index) in categories" :key="index">
                                <td>
                                    <div>
                                        {{ category.name }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ category.products_count?category.products_count:0 }}
                                    </div>
                                </td>
                                <td>
                                    <v-icon class='dark-blue-color ma-1' @click="editCategory(category.id)" title="Edit Category" v-if="checkWritePermission($modules.pos.management.slug)">mdi-pencil-outline</v-icon>
                                    <v-icon class='dark-blue-color ma-1' @click="deleteData(category.id,'category')" title="Delete Category" v-if="checkDeletePermission($modules.pos.management.slug)">mdi-delete-outline</v-icon>
                                </td>
                            </tr>
                            
                            <tr v-if="categories && !categories.length">
                                <th colspan="5">category Not Found</th>
                            </tr>
                        </table>
                        <table class="pm-table mb-5" id="pm-table" with="100%" v-if="amClass">
                            <tr>
                                <th>Attribute Name</th>
                                <th>Action</th>
                            </tr>
                            <tr v-for="(attr, index) in attributes" :key="index">
                                <td>
                                    <div>
                                        {{ attr.attribute_name }}
                                    </div>
                                </td>
                                <td>
                                    <v-icon class='dark-blue-color ma-1' @click="editAttribute(attr.id)" title="Edit Attribute" v-if="checkWritePermission($modules.pos.management.slug)">mdi-pencil-outline</v-icon>
                                    <v-icon class='dark-blue-color ma-1' @click="deleteData(attr.id,'attribute')" title="Delete Attribute" v-if="checkDeletePermission($modules.pos.management.slug)">mdi-delete-outline</v-icon>
                                </td>
                            </tr>
                            
                            <tr v-if="attributes && !attributes.length">
                                <th colspan="2">Attribute Not Found</th>
                            </tr>
                        </table>
                        <table class="pm-table mb-5" id="pm-table" with="100%" v-if="imClass">
                            <tr>
                                <th>Product Name</th>
                                <th>Stock In</th>
                                <th>Sold</th>
                                <th>Refund</th>
                                <th>Stock Out</th>
                                <th>Available</th>
                                <th>Action</th>
                            </tr>
                            <tr v-for="(stock, index) in inventories" :key="index">
                                <td>
                                    <div>
                                        {{ stock.name }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ stock.purchased_qty}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ stock.sold_qty}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ stock.refund_qty}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ stock.out_qty}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ stock.available_qty}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                         <v-icon class='dark-blue-color ma-1' @click="viewProductAnalytics(stock.product_id)" title="View Logs" v-if="checkWritePermission($modules.pos.management.slug)">mdi-history</v-icon>
                                    </div>
                                </td>
                            </tr>   
                            
                            <tr v-if="inventories && !inventories.length">
                                <th colspan="5">Inventory Not Found</th>
                            </tr>
                        </table>
                        <v-pagination
                            v-if="pmClass && totalPages > 0"
                            v-model="page"
                            :length="totalPages"
                        ></v-pagination>
                        <v-pagination
                            v-if="amClass && aTotalPages > 0"
                            v-model="aPage"
                            :length="aTotalPages"
                        ></v-pagination>
                        <v-pagination
                            v-if="cmClass && cTotalPages > 0"
                            v-model="cPage"
                            :length="cTotalPages"
                        ></v-pagination>
                        <v-pagination
                            v-if="imClass && iTotalPages > 0"
                            v-model="iPage"
                            :length="iTotalPages"
                        ></v-pagination>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        
    </v-row>
    
    <PosProductForm :productDialog="productDialog" :id="currentProductId" :venueServiceId="venueServiceId" :categories="categories"  @close="productDialog=false;currentProductId=null;" @added="productDialog=false;currentProductId=null;getProductList()" />
    <PosCategoryForm :categoryDialog="categoryDialog" :venueServiceId="venueServiceId" @close="categoryDialog=false;currentCategoryId=null" @added="categoryDialog=false;getCategoryList()" :id="currentCategoryId"/>
    <PosAttributeForm :attributeDialog="attributeDialog" :venueServiceId="venueServiceId" @close="attributeDialog=false;currentAttributeId=null" @added="attributeDialog=false;getAttributeList()" :id="currentAttributeId"/>
    <PosInventoryForm :productId="inventoryProductId" :inventoryDialog="inventoryDialog" :venueServiceId="venueServiceId" @close="inventoryDialog=false;inventoryProductId=null;" @added="inventoryDialog=false;inventoryProductId=null;getInventoryList()" />
    <PosProductInventoryLogPopup :productId="inventoryProductId" :inventoryLogDialog="inventoryLogDialog" :venueServiceId="venueServiceId"  @close="inventoryLogDialog=false;inventoryProductId=null;" />
    
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
    </v-container>
</template>

<script>
import PosProductForm from "@/views/Pos/PosProductForm.vue";
import PosCategoryForm from "@/views/Pos/PosCategoryForm.vue";
import PosAttributeForm from "@/views/Pos/PosAttributeForm.vue";
import PosInventoryForm from "@/views/Pos/PosInventoryForm.vue";
import PosProductInventoryLogPopup from "@/views/Pos/PosProductInventoryLogPopup.vue";
import images from "@/utils/images";
export default {
    components: {
        PosProductForm,PosCategoryForm,PosAttributeForm,PosInventoryForm,PosProductInventoryLogPopup
    },
    data() {
        return {
            image_path:'/../../src/assets/images/pos-img/1.png',
            cartItems: [],
            categories: [],
            products: [],
            attributes: [],
            inventories: [],
            right: null,
            productNameSearch:"",
            selectedCategory:'all',
            selectedTab:null,
            customerDialog: false,
            pmClass: true,
            cmClass: false,
            amClass: false,
            imClass: false,
            productDialog: false,
            categoryDialog: false,
            attributeDialog:false,
            inventoryDialog:false,
            inventoryLogDialog:false,
            page: 1,
            perPage: null,
            totalPages: 1,
            currentCategoryId: null,
            currentProductId: null,
            currentAttributeId: null,
            currentInventoryId: null,
            inventoryProductId: null,
            confirmModel: {
                id: null,
                title: null,
                description: null,
            },
            cTotalPages: 1,
            cPage: 1,
            aTotalPages: 1,
            aPage: 1,
            iPage:1,
            iTotalPages:1,
        }
    },
    mounted() {
        console.log("this.$router.currentRoute.name ");
        console.log(this.$router.currentRoute.name);
        if (this.$store.getters.getVenueServices.status == false) {
            this.$store.dispatch("loadVenueServices").then(() => {
                this.getCategoryList();
                this.getAttributeList();
            });
        }
        if (this.$store.getters.getTaxTypes.status == false) {
            this.$store.dispatch("loadTaxTypes");
        }
        if (this.$router.currentRoute.name == "CategoryManagement") {
            this.updateTabClasses(false,false,true,false);
            this.getCategoryList();
        } else if (this.$router.currentRoute.name == "ProductManagement") {
            this.updateTabClasses(true,false,false,false);
            this.getCategoryList();
            this.getProductList();
        } else if (this.$router.currentRoute.name == "AttributeManagement") {
            this.updateTabClasses(false,true,false,false);
            this.getAttributeList(10);
        }else if (this.$router.currentRoute.name == "InventoryManagement") {
            this.updateTabClasses(false,false,false,true);
            this.getInventoryList(10);
        }else {
            this.updateTabClasses(true,false,false,false);
            this.getCategoryList();
            this.getProductList();
        }
    },
    watch: {
        page() {
            this.searchData();
        },
        cPage() {
            this.searchData();
        },
        aPage() {
            this.searchData();
        },
        iPage() {
            this.searchData();
        },
        $route(to) {
            if (to.name == "CategoryManagement") {
                this.productNameSearch = "";
                this.updateTabClasses(false,false,true,false);
                this.getCategoryList();
            } else if (to.name == "ProductManagement") {
                this.productNameSearch = "";
                this.updateTabClasses(true,false,false,false);
                this.getProductList();
            } else if (to.name == "AttributeManagement") {
                this.productNameSearch = "";
                this.updateTabClasses(false,true,false,false);
                this.getAttributeList(10);
            }else if (this.$router.currentRoute.name == "InventoryManagement") {
                this.updateTabClasses(false,false,false,true);
                this.getInventoryList(10);
            } else {
                this.updateTabClasses(true,false,false,false);
                this.getProductList();
            }
        }
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Add Product' : 'Edit Product'
        },
        venueServiceId() {
            const filteredService = this.$store.getters.getVenueServices.data.find(item => item.name.toLowerCase() === "pos");
            if (filteredService) {
                return filteredService.id;
            } else {
                return null;
            }
        },  
    },
    methods: {
        updateTabClasses(pmClass,amClass,cmClass,imClass){
            this.pmClass = pmClass;
            this.amClass = amClass;
            this.cmClass = cmClass;
            this.imClass = imClass;
        },
        resetDialogs(pmClass,amClass,cmClass,imClass){
            this.productDialog = pmClass;
            this.attributeDialog = amClass;
            this.categoryDialog = cmClass;
            this.inventoryDialog = imClass;
        },
        backToPos() {
            this.$router.push({name: "PosView"});
        },
        clearSearch() {
            setTimeout(() => {
                this.getProductList(this.currentYear, true);
            }, 100);
        },
        getProductList() {
            this.showLoader("Loading");
            let url = `venues/pos/management/products?page=`;
            url += this.page + "&per_page=" + (this.perPage != null ? this.perPage : 10)
            url += `${this.productNameSearch ? "&product_name=" + this.productNameSearch : ""}`;
            
            this.$http.get(url).then((response) => {
                if (response && response.status == 200) {
                    const data = response.data.data;
                    if (data && data.length) {
                        this.totalPages = response.data.total_pages;
                        this.products = data;
                    }else{
                        this.products = [];
                        this.totalPages = 1;
                        
                    }
                    this.hideLoader();
                }
            }).catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
            });
            
        },
       
        getCategoryList() {
            if (this.venueServiceId) {
                this.showLoader("Loading");
                // let url = `venues/pos?category=${this.selectedCategory}`;
                let url = `venues/pos/management/category?venue_service_id=${this.venueServiceId}&page=`;
                url += this.cPage + "&per_page=" + (this.perPage != null ? this.perPage : 50)
                url += `${this.productNameSearch ? "&name=" + this.productNameSearch : ""}`;

                this.$http.get(url).then((response) => {
                    if (response && response.status == 200) {
                        const data = response.data.data;
                        if (data && data.length) {
                            this.cTotalPages = response.data.total_pages;
                            this.categories = data;
                        } else {
                            this.categories = [];
                            this.cTotalPages = 1;
                        }
                        this.hideLoader();
                    }
                }).catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });
            }
            
        },

        getAttributeList(per_page = 10) {
            if (this.venueServiceId) {
                this.showLoader("Loading");
                // let url = `venues/pos?category=${this.selectedCategory}`;
                let url = `venues/pos/management/attributes?venue_service_id=${this.venueServiceId}&page=`;
                url += this.aPage + "&per_page=" + (per_page != null ? per_page : 10)
                url += `${this.productNameSearch ? "&name=" + this.productNameSearch : ""}`;

                this.$http.get(url).then((response) => {
                    if (response && response.status == 200) {
                        const data = response.data.data;
                        if (data && data.length) {
                            this.aTotalPages = response.data.total_pages;
                            this.attributes = data;
                        } else {
                            this.attributes = [];
                            this.aTotalPages = 1;
                        }
                        this.hideLoader();
                    }
                }).catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });
            }
            
        },

        getInventoryList() {
            if(this.pmClass){
                this.getProductList();
                return;
            }else{
                this.showLoader("Loading");
                let url = `venues/pos/management/inventory?&page=`;
                url += this.iPage + "&per_page=" + (this.perPage != null ? this.perPage : 50)
                url += `${this.productNameSearch ? "&name=" + this.productNameSearch : ""}`;
                this.$http.get(url).then((response) => {
                    if (response && response.status == 200) {
                        const data = response.data.data;
                        if (data && data.length) {
                            this.iTotalPages = response.data.total_pages;
                            this.inventories = data;
                        } else {
                            this.inventories = [];
                            this.iTotalPages = 1;
                        }
                        this.hideLoader();
                    }
                }).catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });
            }
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        addData() {
            if (this.pmClass) {
                this.resetDialogs(true,false,false,false);
            } else if(this.amClass) {
                this.resetDialogs(false,true,false,false);
            } else if(this.cmClass) {
                this.resetDialogs(false,false,true,false);
            } else if(this.imClass) {
               this.resetDialogs(false,false,false,true);
            }else {
                this.resetDialogs(false,false,false,false);
            }
        },
        searchData() {
            if (this.pmClass) {
                this.getProductList();
            } else if(this.cmClass) {
                this.getCategoryList();
            } else if(this.amClass) {
                this.getAttributeList(10);
            } else if(this.imClass) {
                this.getInventoryList(10);
            }
        },
        editCategory(id){
            this.currentCategoryId = id;
            this.categoryDialog = true;
        },
        editProduct(id){
            this.currentProductId = id;
            this.productDialog = true;
        },
        editAttribute(id){
            this.currentAttributeId = id;
            this.attributeDialog = true;
        },
        deleteData(id,type) {
            this.confirmModel = {
                id: id,
                title: `Do you want to delete this ${type}?`,
                description:
                "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
                type: type,
            };
        },
        confirmActions(data) {
            let type = "";
            if (data.type == "product") {
                type = "products/" + data.id;
            } else if (data.type == "category") {
                type = "category/" + data.id;
            }else if (data.type == "attribute") {
                type = "attributes/" + data.id;
            } else {
                return false;
            }
            this.showLoader("Loading");
            let url = `venues/pos/management/${type}`;
            this.$http.delete(url).then((response) => {
                if (response.status == 200) {
                    const res = response.data;
                    if(res){
                        if (data.type == 'product') {
                            this.showSuccess("Product deleted successfully.");
                            this.getProductList();
                        } else if (data.type == "category") {
                            this.showSuccess("Category deleted successfully.");
                            this.getCategoryList();
                        } else if (data.type == "attribute") {
                            this.showSuccess("Attribute deleted successfully.");
                            this.getAttributeList();
                        }  
                    }
                }else{
                    this.hideLoader();
                }
            }).catch((error) => {
                 this.hideLoader();
                this.errorChecker(error);
            });

        },
        getImage(image,type = 'product'){
            return image && image != 'null'
                ? this.s3BucketURL+image
                : type == 'product'?images['default']:images['empty_cart'];
        },
        addProductStock(product_id = null){
            if(product_id){
                this.inventoryProductId = product_id;
            }
            this.resetDialogs(false,false,false,true);
        },
        viewProductAnalytics(product_id){
            this.inventoryProductId = product_id;
            this.inventoryLogDialog = true;
        }   
    },
};
</script>
<style scoped>

table.pm-table {
  width: 100%;
}
table.pm-table tr th,
table.pm-table tr td {
  text-align: center;
  border-bottom: 1px solid #000;
}

.pm-table {
  border-collapse: collapse;
  border-radius: 6px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #dbdbdb; /* this draws the table border  */
}
.pm-table th,
.pm-table td,
.reciept_details_total_table th,
.reciept_details_total_table td {
  border: transparent !important;
  color: #112a45;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 10px 0px;
}

.pm-table tr th {
  text-align: center;
  padding: 10px 0px;
}
.pm-table tr:first-child th {
  background-color: #E9F1F6;
}
.pm-table .order-number {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

table.pm-table.child {
  margin: 0 3%;
  width: 93%;
}
table.pm-table.child th {
  background-color: #112a45;
  color: #f7f7f5;
}

.dark-blue-color{
    color: #112A46;
}
.pm-title{
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}
.product-management .btn-tab{
    background: #FFF;
}
.product-management .btn-tab.active{
    color:#112A46;
}
.product-management .btn-add-product{
    color: var(--white, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 4px;
    background: var(--dark-blue, #112A46);
}
.product-management .pm-card {
    box-shadow: 0 0 0 1px #dbdbdb;
    border-radius: 6px;
}
.product-management .v-card__title.cart-title {
    padding: 20px 35px !important;
}
.product-management .v-card__title.cart-title .text-h5 {
    padding: 10px 0px;
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
</style>
