<template>
    <div>
        <v-dialog v-model="inventoryLogDialog" width="800" @input="closeEdit"  persistent>
            <v-card style="border-radius:12px">
                <v-card-text class="pos-cf-body">
                    <v-container>
                        <v-row>
                            <v-col md="12">
                                <div class="pos-cf-action-heading">Product Logs</div>
                            </v-col>
                        </v-row>
                        <table class="pm-table mb-5" id="pm-table" with="100%">
                            <tr>
                                <th>Timestamp</th>
                                <th>Product Name</th>
                                <th>Stock</th>
                                <th>Transaction</th>
                                <th>Cashier Name</th>
                            </tr>
                            <tr v-for="(stock, index) in logs" :key="index">
                                <td>
                                    <div>
                                        {{ stock.timestamp | timeStamp }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ stock.name }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ stock.stock_qty }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{  STOCK_STATUS[stock.transaction_type] || stock.transaction_type }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ stock.first_name }} {{ stock.last_name?stock.last_name:'' }}
                                    </div>
                                </td>
                            </tr>   
                            <tr v-if="logs && !logs.length">
                                <th colspan="5">Logs Not Found</th>
                            </tr>
                        </table>
                        <v-pagination
                            v-if="totalPages > 0"
                            v-model="page"
                            :length="totalPages"
                        ></v-pagination>
                    </v-container>
                </v-card-text>
                <v-card-actions class="pos-cf-bottom-action">
                <v-btn
                    color="darken-1"
                    class="ma-4 text"
                    text
                    @click="closeEdit"
                    >Close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import constants from '../../utils/constants';
export default {
    props: {
        inventoryLogDialog: { type: Boolean, default: false },
        productId: { type: Number, default: null },
        venueServiceId:{  type: Number, default: null },
    },
    data() {
        return {
            valid: true,
            logs:[],
            product_stock: [{
                product_id: "",
                qty:0
            }],
            page: 1,
            perPage: null,
            totalPages: 1,
            STOCK_STATUS: constants.STOCK_STATUS,
        };
    },
    watch:{
        inventoryLogDialog: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (val) {
                        this.getProductLogs();
                    }
                }
            },
        },
        page() {
            this.getProductLogs();
        },
    },
    mounted() {
        this.getProductLogs();
    },
    methods: {
        closeEdit() {
            this.logs = [];
            this.$emit("close");
        },
        getProductLogs() {
          if (this.venueServiceId && this.productId) {
            this.logs = [];
            this.showLoader("Loading");
            let url = `venues/pos/management/inventory/product/${this.productId}?page=`;
            url += this.page + "&per_page=" + (this.perPage != null ? this.perPage : 10)
            this.$http.get(url).then((response) => {
                if (response.status == 200) {
                    const data = response.data.data;
                    if (data && data.length) {
                        this.logs = data;
                    } else {
                        this.logs = [];
                    }
                    this.hideLoader();
                }
            }).catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
            });
          }
        },
    },
};
</script>

<style scoped>
.product-log-form {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}
.pos-cf-action-heading {
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 18px;
}

table.pm-table {
  width: 100%;
}
table.pm-table tr th,
table.pm-table tr td {
  text-align: center;
  border-bottom: 1px solid #000;
}

.pm-table {
  border-collapse: collapse;
  border-radius: 6px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #dbdbdb; /* this draws the table border  */
}
.pm-table th,
.pm-table td,
.reciept_details_total_table th,
.reciept_details_total_table td {
  border: transparent !important;
  color: #112a45;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 10px 0px;
}

.pm-table tr th {
  text-align: center;
  padding: 10px 0px;
}
.pm-table tr:first-child th {
  background-color: #E9F1F6;
}
.pm-table .order-number {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

table.pm-table.child {
  margin: 0 3%;
  width: 93%;
}
table.pm-table.child th {
  background-color: #112a45;
  color: #f7f7f5;
}
/deep/ .v-dialog {
  /* overflow-y: unset; */
  overflow-x: hidden;

}
</style>
