<template>
    <div>
        <v-dialog v-model="inventoryDialog" width="800" @input="closeEdit"  persistent>
            <v-form ref="product_form"  v-model="valid" lazy-validation  class="pos-product-form">
                <v-card-actions class="pos-cf-bottom-action">
                    <v-row>
                        <v-col md="12">
                            <div >
                                <v-radio-group
                                    v-model="stock_type"
                                    row
                                    class="pos-cf-action-heading"
                                    label="STOCK"
                                    >
                                    <v-radio label="IN" value="I"></v-radio>
                                    <v-radio label="OUT" value="O"></v-radio> 
                                </v-radio-group
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card style="border-radius:12px">
                <v-card-text class="pos-cf-body">
                  <v-container>
                    <v-row  class="variant-fields">
                      <v-col cols="12"><h3>Stock Details</h3></v-col>
                      <v-col cols="12" sm="12" md="12" v-for="(stock,index) in product_stock" :key="index">
                        <v-row style="position:relative">
                            <v-col cols="12" sm="12" md="6"> 
                                <v-autocomplete
                                    hide-details="auto"
                                    v-model="stock.product_id"
                                    :items="products"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    background-color="#fff"
                                    :label="`Product Name*`"
                                    required
                                    :rules="[(v) => !!v || 'Product name is required']"
                                />
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    hide-details="auto"
                                    label="Quantity*"
                                    outlined
                                    background-color="#fff"
                                    v-model="stock.qty"
                                    :rules="[(v) => !!v || 'Quantity is required',
                                        v => v > 0 || 'Quantity must be greater than zero',
                                        v => v < 10000 || 'Quantity less than 10000',
                                        
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <div v-if="product_stock.length > 1">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red"
                                    fab
                                    x-small
                                    absolute
                                    top
                                    right
                                    dark
                                    style="top: -9px;right: 0px;"
                                    @click="removeProductStock(index)"
                                    >
                                    <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                Delete
                                </v-tooltip>
                            </div>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <div
                          class="add_btn pb-4"
                          style="margin-top: -30px"
                          v-if="products && products.length > 1"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="teal"
                                fab
                                x-small
                                dark
                                @click="addProductStock"
                              >
                                <v-icon small>mdi-plus-circle</v-icon>
                              </v-btn>
                            </template>
                            Add
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="pos-cf-bottom-action">
                  <v-btn
                      color="darken-1"
                      class="ma-4 text"
                      text
                      @click="closeEdit"
                      >Close</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                      v-if="checkWritePermission($modules.pos.management.slug)"
                      color=" darken-1"
                      class="ma-4 white--text blue-color"
                      @click="addStock"
                      text
                    >Save</v-btn>
                </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
        ></confirm-model>
    </div>
</template>

<script>
export default {
    props: {
        inventoryDialog: { type: Boolean, default: false },
        productId: { type: Number, default: null },
        venueServiceId:{  type: Number, default: null },
    },
    data() {
        return {
            valid: true,
            stock_type: "I",
            products:[],
            product_stock: [{
                product_id: "",
                qty:0
            }],
            confirmModel: {
                id: null,
                title: null,
                description: null,
            },

        };
    },
    watch:{
        inventoryDialog: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (val) {
                        this.product_stock = [{
                            product_id: "",
                            qty:0
                        }];
                        this.getInventoryEnabledProducts();
                    }
                }
            },
        }
    },
    mounted() {
        this.getInventoryEnabledProducts();
    },
    methods: {
        addStock() {
            if (!this.$refs.product_form.validate()) {
                this.showError("Please fill all required fields");
                return;
            }
            let validate = true;
            if(this.validateProductIds(this.product_stock).length){
                validate = false;
                this.showError("Please remove duplicates");
                return;
            }
            this.showLoader();
            var formData = new FormData();
            
            this.product_stock.forEach( (p,index) =>{
                formData.append(`product_stock[${index}][product_id]`, p.product_id);
                formData.append(`product_stock[${index}][qty]`, p.qty);
            });
            formData.append(`stock_type`, this.stock_type);
           
            if (validate) {
                this.$http({
                        method: "post",
                        data: formData,
                        url: `venues/pos/management/inventory`,
                        headers: { "Content-Type": "multipart/form-data; boundary=${form._boundary}",},
                    }).then((response) => {
                        if (response.status == 200) {
                            this.showSuccess("Product stock added successfully ");
                            this.products = [];
                            this.product_stock = [{
                                product_id: "",
                                qty:0
                            }];
                            this.$refs.product_form.resetValidation();
                            this.hideLoader();
                            this.$forceUpdate();
                            this.$emit('added');
                        }else{
                            this.showError(response.message);
                        }
                    }).catch((error) => {
                        this.errorChecker(error);
                        this.hideLoader();
                    });
            }
        },
    
        closeEdit() {
            this.products = {};
            this.product_stock = [];
            this.stock_type = "I";
            this.$refs.product_form.resetValidation();
            this.$emit("close");
        },
        addProductStock() {
            this.product_stock.push(
                {
                product_id: "",
                qty: 0,
                });
        },
    
        removeProductStock(index) {
            if (this.product_stock[index]) {
            this.product_stock.splice(index, 1);
            }
        },
        confirmActions(data) {
            if (data.type == "delete") {
                this.product_stock.splice(data.id, 1);
            }
            this.confirmModel.id = null;
        },
        getInventoryEnabledProducts() {
          if (this.venueServiceId) {
            this.products = [];
            this.showLoader("Loading");
            // let url = `venues/pos?category=${this.selectedCategory}`;
            let url = `venues/pos/management/inventory/products?venue_service_id=${this.venueServiceId}&page=1&per_page=1000&enable_retail_inventory=1&parent_id=${this.productId}`;
            this.$http.get(url).then((response) => {
                if (response.status == 200) {
                    const data = response.data.data;
                    if (data && data.length) {
                        if(this.productId){
                            if(data[0].variants && data[0].variants.length>0){
                                console.log(data[0].variants);
                                this.products = data[0].variants;
                                this.product_stock[0].product_id = data[0].variants[0].id;
                                this.product_stock[0].qty = 0;
                            }else{
                                this.products = data;
                                this.product_stock[0].product_id = data[0].id;
                                this.product_stock[0].qty = 0;
                                 console.log(this.products);
                                console.log(this.product_stock);
                            }
                        }else{
                            this.products = data;
                        }
                    } else {
                        this.products = [];
                    }
                    this.hideLoader();
                }
            }).catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
            });
          }
        },
        validateProductIds(products){
            const seen = new Set();
            const duplicates = [];

            products.forEach((product) => {
                const { product_id } = product;
                if (seen.has(product_id)) {
                    // Duplicate found
                    duplicates.push(product_id);
                } else {
                    seen.add(product_id);
                }
            });
            return duplicates;
        }
    },
};
</script>

<style scoped>
.pos-cf-action-heading {
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 0px 0px 10px;
}

form.v-form.pos-product-form {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

button.ma-2.text.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-skip-btn {
    color: #A7A7A7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

button.ma-2.white--text.blue-color.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-btn-next {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 75px;
    height: 40px;
}
.variant-fields {
    border: 1px solid #ccc;
    position: relative;
    /* max-height: 280px;
    overflow-y: auto; */
}
/deep/ .v-dialog {
  /* overflow-y: unset; */
  overflow-x: hidden;

}
</style>
