<template>
    <div>
        <v-dialog v-model="attributeDialog" width="480" @input="closeEdit"  persistent>
            <v-form ref="attri_form" autocomplete="off" v-model="valid" class="pos-product-form">
                <v-card-actions class="pos-cf-bottom-action">
                    <v-row>
                        <v-col md="12">
                            <div class="pos-cf-action-heading">{{  attribute.id?'Edit Attribute':'Add Attribute' }}</div>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card style="border-radius:12px">
                    <v-card-text class="pos-cf-body">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                                    <v-text-field
                                        outlined
                                        background-color="#fff"
                                        v-model="attribute.attribute_name"
                                        hide-details="auto"
                                        label="Attribute Name *"
                                        placeholder="Attribute Name"
                                        required
                                        :rules="[(v) => !!v || 'Attribute is required']"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="pos-cf-bottom-action">
                    <v-btn
                        color="darken-1"
                        class="ma-4 text"
                        text
                        @click="closeEdit"
                        >Close</v-btn>
                        <v-spacer></v-spacer>
                    
                        <v-btn
                        v-if="checkWritePermission($modules.pos.management.slug)"
                        color=" darken-1"
                        class="ma-4 white--text blue-color"
                        @click="addAttribute"
                        text
                        >Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        attributeDialog: { type: Boolean, default: false },
        id: { type: Number, default: null },
        venueServiceId: { type: Number, default: null}    
    },
    data() {
        return {
            valid: false,
            attribute: {},
        };
    },
    watch: {
        id: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getAttributes(val);
                }
            },
        }
    },
    mounted() {},
    methods: {
        addAttribute() {
            if (!this.$refs.attri_form.validate()) {
                this.showError("Please fill all required fields");
                return;
            }
            if (!this.venueServiceId) {
                this.showError("Venue Service Required, Please refresh the page.");
                return false;
            }
            this.showLoader();
            var formData = new FormData();
            for (let key in this.attribute) {
                if (this.attribute[key] != null && key != "id") {
                formData.append(key, this.attribute[key]);
                }
            }
            formData.append('venue_service_id', this.venueServiceId);  
            
            this.$http({
                method: "post",
                data: formData,
                url: `venues/pos/management/attributes${
                this.attribute.id ? "/" + this.attribute.id : ""
                }`,
                headers: {
                "Content-Type": "multipart/form-data; boundary=${form._boundary}",
                },
            }).then((response) => {
                if (response.status == 200) {
                    this.showSuccess(
                    "Attribute successfully " + this.attribute.id ? "updated" : "saved."
                    );
                    this.category = {};
                    this.attribute = {};
                    this.$refs.attri_form.reset();
                    this.hideLoader();
                    this.$emit('added');
                }
                })
                .catch((error) => {
                    this.errorChecker(error);
                    this.hideLoader();
                });
        },
        getAttributes() {
            this.showLoader("Loading");
            if (this.id) {
                let url = `venues/pos/management/attributes/`+this.id;
                this.$http.get(url).then((response) => {
                    if (response.status == 200) {
                        const data = response.data.data;
                        if(data){
                            this.attribute = data;
                        }else{
                            this.attribute = {};
                        }
                        this.hideLoader();
                    }
                }).catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });
            }
        },
        closeEdit() {
            this.attribute = {};
            this.$refs.attri_form.reset();
            this.$emit("close");
        },
        
    },
};
</script>

<style scoped>
.pos-cf-action-heading {
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 18px;
}

form.v-form.pos-product-form {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

button.ma-2.text.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-skip-btn {
    color: #A7A7A7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

button.ma-2.white--text.blue-color.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-btn-next {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 75px;
    height: 40px;
}
/deep/ .v-dialog {
    overflow-y: unset;
}
</style>
